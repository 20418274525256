
.fade-enter {
  opacity: 0;
  transform: translateX(-50px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1500ms ease-in-out;
}

.inout-enter {
  opacity: 0;

}

.inout-enter-active {
  opacity: 1;
  transition: 1500ms opacity ease-in-out;
}

.inout-exit {
  opacity: 1;
}

.inout-exit-active {
  opacity: 0;
  transition: opacity 1500ms ease-in-out;
}
@primary-color: #1890ff;