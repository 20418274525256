
.site-layout-background {
  background: #fff;
  padding: 0;
  height: 50px;
  border-bottom: #bfbfbf52 1px solid;
}

.accountOpt {
  line-height: 40px;
}

.action {
  cursor: pointer;
  align-items: center;

  &:hover {
    opacity: .7;
  }
}

.adminLayout {

}

.userName {
  padding: 5px;
}

.fixedSider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.logo {
  font-size: 16px;
  text-align: center;
}

.logoImg {
  height: 70px;
  margin-top: 5px;
  background-size: 100% 100%;
}

.mini-logo {
  text-align: center;
}

.mini-logoImg {
  height: 35px;
  margin: 5px;
  background-size: 100% 100%;
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: unset;
}

@primary-color: #1890ff;