.content {
  position: sticky;
  background-color: white;
  max-height: 80vh;
  min-height: 50vh;
  padding: 8px;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  overflow-x: auto;
  border: 2px solid rgba(0,0,0,.06);
}

@primary-color: #1890ff;